import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Community from "../pages/community";
import Profile from "../pages/profile";
import EditProfile from '../pages/EditProfile';
import About from "../pages/about";
import Post from "../pages/post";
import Posts from "../pages/posts";
import Group from "../pages/group";
import Discussion from "../pages/discussion";
import DiscussionOverview from "../pages/discussionOverview";
import Communities from "../pages/communities";
import Login from "../pages/login";
import ChatBox from "../pages/chat";
import NotFound from "../pages/notFound";
import Unauthorized from "../pages/unauthorized";
import Home from "../pages/home.js";
import Register from "../pages/register.js";
import ProtectedRoute from "../config/ProtectedRoute.js";
import CreateCommunities from "../pages/createcommunities.js"
import ForgotPass from "../pages/forgotPassword.js";
import VerifyEmail from "../pages/verifyEmail.js";
import AcceptCommunity from "../components/acceptcommunity.js";
import Products from "../components/products.js";
import UserProfile from "../pages/userProfile.js";
import AccountVerify from "../pages/accountVerify.js";
import Setting from "../pages/setting.js";
import MemberList from "../components/memberList.js";
import VendorRegister from "../pages/vendorRegister.js";
import VendorRegisterforbusiness from "../pages/vendorRegisterforbusiness.js";
import AcceptInviteCommunity from "../components/acceptInviteCommunity.js";
import AcceptChatPrivateGroup from "../components/acceptChatPrivateGroup.js";
import IndividualSeller from "../pages/individualseller.js";
import VendorRegisterfororganization from "../pages/vendorRegisterfororganization.js"
import ProfileCommunity from "../pages/profileCommunity.js";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import UserProfilefeed from "../pages/userProfilefeed.js";
import MarketplaceTerms from "../pages/terms.js";
import RequestTojoin from "../components/requestTojoin.js";
const RoutePage = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/marketplaceTerms" element={<MarketplaceTerms />} />
        <Route path="/profile/:userid/" element={<ProtectedRoute element={<UserProfile />} />} />
         <Route path="/profilefeed/:userid/" element={<ProtectedRoute element={<UserProfilefeed />} />} />
        
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/verifyemail/:userid/:token" element={<VerifyEmail />} />
        <Route path="/community/:communityHandle/about" element={<About />} />

        <Route
          path="/reverifyemail" element={<AccountVerify />}
        />

        <Route
          // path="/create-community/:communityHandle/:cid"
          path="/create-community"
          element={<ProtectedRoute element={<CreateCommunities />} />}
        />

        <Route
          path="/settings"
          element={<ProtectedRoute element={<Setting />} />}
        />

        <Route
          path="/communities"
          element={<Communities />} 
        />
        <Route
          path="/chats"
          element={<ProtectedRoute element={<ChatBox />} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/edit-profile"
          element={<ProtectedRoute element={<EditProfile />} />}
        />
        <Route path="/community/:communityHandle" element={<ProtectedRoute element={<Community />} />} />
        <Route path="/community/:communityHandle/posts" element={<ProtectedRoute element={<Posts />} />} />
        <Route path="/community/:communityHandle/actions/join/:userid/:cid" element={<AcceptCommunity />} />
        <Route path="/community/:communityHandle/actions/invite/:userid/:cid" element={<AcceptInviteCommunity />} />
        <Route path="/private-group" element={<AcceptChatPrivateGroup />} />

        <Route path="/community/:communityHandle/users/:cid/:caid" element={<ProtectedRoute element={< MemberList />} />} />
        <Route path="/community/:communityHandle/requesttojoin/:cid/:caid" element={<ProtectedRoute element={<RequestTojoin />} />} />

        <Route
          path="/community/:communityHandle/posts/:postId"
          element={<ProtectedRoute element={<Post />} />}
        />
        <Route
        path="/profilecommunity"
        element={<ProtectedRoute element={<ProfileCommunity />} />}
      />


        <Route path="/community/:slug/group" element={<Group />} />
        {/* <Route path="/community/:slug/:slug/group-overview" element={<DiscussionOverview />} /> */}
        <Route path="/community/:slug/discussion" element={<Discussion />} />
        <Route path="/community/:communityHandle/products" element={<ProtectedRoute element={<Products />} />} />

        <Route path="/community/:slug/:slug/discussion-overview" element={<DiscussionOverview />} />
        <Route path="/vendorRegister" element={<ProtectedRoute element={<VendorRegister />} />} />
        <Route path="/individualseller" element={<IndividualSeller />} />

        <Route path="/businessRegister" element={<ProtectedRoute element={<VendorRegisterforbusiness />} />} />
        <Route path="/organizationRegister" element={<ProtectedRoute element={<VendorRegisterfororganization />} />} />
      </Routes>

      {/* <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/" element={<Home />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/developer" element={<Development />} />
        <Route path="/book-demo" element={<BookingForm />} />
        <Route path="/view-demo" element={<ViewDemo />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reset-password" element={<ResetPassword/>} />

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute element={<Dashboard />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/products"
          element={
            <ProtectedRoute element={<Products />} requiredAccess="admin" />
          }
        />
        <Route
          path="/admin/users"
          element={<ProtectedRoute element={<Users />} requiredAccess="user" />}
        />
        <Route
          path="/admin/leads"
          element={<ProtectedRoute element={<Leads />} requiredAccess="user" />}
        />
        <Route
          path="/admin/add-products"
          element={
            <ProtectedRoute element={<AddProducts />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/support"
          element={
            <ProtectedRoute element={<Support />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/profile"
          element={
            <ProtectedRoute element={<Profile />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/product/:id"
          element={
            <ProtectedRoute element={<Product />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/add-user"
          element={
            <ProtectedRoute element={<AddUser />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/settings"
          element={
            <ProtectedRoute element={<Settings />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/organizations"
          element={
            <ProtectedRoute element={<Organizations />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/organization/:id"
          element={
            <ProtectedRoute element={<Organization />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/configurations"
          element={
            <ProtectedRoute
              element={<Configurations />}
              requiredAccess="user"
            />
          }
        />
        <Route
          path="/admin/inquiry"
          element={
            <ProtectedRoute element={<Inquiry />} requiredAccess="master" />
          }
        />
      </Routes> */}
    </BrowserRouter>
  );
};

export default RoutePage;
