import React, { useEffect, useState, useRef } from "react";
import { FaRegUser, FaWpforms } from "react-icons/fa6";
import { GrLocation, GrGroup } from "react-icons/gr";
// import { GoChecklist } from "react-icons/go";
import { CiEdit } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { VscMail } from "react-icons/vsc";
import {
  MdOutlineDateRange,
  MdOutlinePostAdd,
  MdProductionQuantityLimits,
  MdNotificationsActive,
} from "react-icons/md";
import { getCookie, removeCookie, setCookie } from "../config/coockies";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import LogoutIcon from '@mui/icons-material/Logout';
import { MdLogout } from "react-icons/md";
import axios from "axios";
import { apiurl } from "../config/config";
import { useUser } from "../contexts/UserProvider";
import LoaderSpiner from "../components/loader";
import Orders from "../components/orders";
import { FaLayerGroup } from "react-icons/fa";
import ProfilePost from "../components/profilepost";
import ProfileCommunity from "../components/profileCommunity";
import UpdateProfile from "../components/updateProfile";
import MyFollowers from "../components/myfollowers";
// import ImageUpload from "../components/imageUpload";
import Layout from "../components/layout";
import Wishlist from "../components/wishlist";
import UserProfilefeed from "./userProfilefeed";
import ImageCropper from "../components/imageCropper";
const Profile = () => {
  const { userData } = useUser();
  const token = getCookie("savage_token");
  const [getprofiledata, setGetProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("my profile");
  const [updateProfile, setUpdateProfile] = useState(false);
  // const [isProfileImg, setIsProfileImg] = useState(false);
  // const [isBgImg, setIsBgImg] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showImageType, setShowImageType] = useState(null);
  const [following, setFollowing] = useState([]);
  const [followReq, setgetFollowReq] = useState([]);
  const [isImageCrop, setIsImageCrop] = useState(false);
  const [croppedProfileImg, setCroppedProfileImg] = useState(null);
  const [cropImageType, setCropImageType] = useState("");
  const [suggestMember, setSuggestMember] = useState([]);
  const coverFileInputRef = useRef(null);
  const heroFileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      removeCookie("savage_token");
      removeCookie("tile");
      // await navigate("/login");
      window.location.reload();
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetProfileData = async () => {
    setLoading(true);
    try {
      const userId = {
        id: userData._id,
      };
      const response = await axios.get(`${apiurl}/user/get/${userId.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.data.success === true) {
        setCookie("tile", response.data.response.hero_img);
        setLoading(false);
        setGetProfileData(response.data.response);
      } else {
        console.error("Failed to get the profile details");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error.message);
      setLoading(false);
    }
  };
  const handleGetSuggestMemberList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/follow/get-suggestion-user/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('Suggest all member data is >>>>>>>>>>>', response.data);
      if (response.data.success === true) {
        setLoading(false);
        setSuggestMember(response.data?.suggestedUsers);
      } else {
        setLoading(false);
        console.error("no member available");
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetProfileData();
    handleGetFollowers();
    handleGetFollowing();
    handleGetFollowRequest();
    handleGetSuggestMemberList();
  }, [updateProfile]);

  const handleGetFollowers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/followers/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setFollowers(response?.data?.follower);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all follower", error);
    }
  };
  const handleGetFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/following/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setFollowing(response?.data?.following);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };

  const handleGetFollowRequest = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/get-request/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        const responseData = response.data;
        setgetFollowReq(responseData?.response);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("get follow request", error);
    }
  };

  const handleCloseCrop = () => {
    setIsImageCrop(false);
    coverFileInputRef.current.value = "";
    heroFileInputRef.current.value = "";
    setCropImageType("");
  };

  const handleCoverIconClick = () => {
    coverFileInputRef.current.click();
  };
  const handleHeroIconClick = () => {
    heroFileInputRef.current.click();
  };

  const handleFileChange = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setCroppedProfileImg(URL.createObjectURL(file));
        setCropImageType(type);
        setIsImageCrop(true);
      };
    }
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading..." /> : null}
      <Layout>
        <div className="bg-gray-200 p-4 mb-12">
          <>
            <div className="bg-white p-4 rounded-xl">
              <div className="w-full sm:w-full mx-auto">
                <div className="relative">
                  <img
                    src={getprofiledata.cover_img}
                    alt="Profile-bg"
                    className="w-full h-auto sm:h-[250px] lg:h-[400px] rounded-xl sm:mb-4"
                    onClick={() => {
                      setShowImage(true);
                      setShowImageType("cover_img");
                    }}
                  />

                  <button onClick={handleCoverIconClick}>
                    <input
                      className="w-7 h-7 hidden z-50 text-black cursor-pointer absolute top-3 right-3 sm:top-4 sm:right-4 border border-black p-1 rounded-full bg-white"
                      type="file"
                      ref={coverFileInputRef}
                      id="bgImage"
                      onChange={(e) => handleFileChange("cover_img", e)}
                    />
                    <CiEdit className="w-7 h-7 text-black cursor-pointer absolute top-2 right-2 sm:top-4 sm:right-4 border border-black p-1 rounded-full bg-white" />
                  </button>
                  <div className="absolute sm:top-40 lg:top-80 top-14 sm:left-8 left-4">
                    <div className="border-2 border-black p-1 sm:p-2 rounded-full bg-white">
                      <img
                        src={getprofiledata.hero_img}
                        alt="Profile-pic"
                        className="sm:w-32 sm:h-32 w-12 h-12 rounded-full"
                        onClick={() => {
                          setShowImage(true);
                          setShowImageType("hero_img");
                        }}
                      />
                    </div>
                    <button onClick={handleHeroIconClick}>
                      <input
                        className="w-4 sm:w-7 h-4 sm:h-7 mr-2 sm:mr-4 absolute text-black bottom-6 sm:bottom-7 -right-1 sm:right-0 cursor-pointer border border-black p-1 rounded-full bg-white opacity-0"
                        type="file"
                        ref={heroFileInputRef}
                        id="bgImage"
                        onChange={(e) => handleFileChange("hero_img", e)}
                      />
                      <CiEdit className="w-7 h-7 mr-2 sm:mr-4 absolute text-black bottom-5 sm:bottom-7 -right-2 sm:right-0 cursor-pointer border border-black p-1 rounded-full bg-white" />
                    </button>
                  </div>
                </div>

                {showImage && (
                  <div
                    className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-90 "
                    onClick={() => setShowImage(false)}
                  >
                    <img
                      src={
                        showImageType === "cover_img"
                          ? getprofiledata.cover_img
                          : showImageType === "hero_img"
                          ? getprofiledata.hero_img
                          : null
                      }
                      alt="Profile-pic"
                      className="max-w-full max-h-full rounded-lg"
                    />
                  </div>
                )}

                <div className="flex justify-between mt-1 sm:mt-16 md:mt-4 lg:mt-4 xl:mt-0 min-h-[90px] sm:h-auto">
                  <div className="flex flex-col justify-center w-7/12 sm:w-[30%] mx-2 md:mx-44 lg:mx-44">
                    <p className=" text-lg font-bold sm:mt-0 mt-1 capitalize">
                      {getprofiledata?.firstname} {getprofiledata?.lastname}
                    </p>
                    <p className="text-gray-600 text-sm font-medium">
                      {getprofiledata?.designation}
                    </p>
                    <p className="text-gray-600 text-sm font-medium overflow-hidden text-ellipsis">
                      {getprofiledata?.bio}
                    </p>
                    {followers && (
                      <div
                        onClick={() => setActiveTab("myfollowers")}
                        className="cursor-pointer mb-2"
                      >
                        <p className="text-[#005AE6] font-bold">
                          {followers?.length} {followers?.length > 1 ? 'Followers' :'Follower'}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="md:flex justify-betweenn max-h-12 items-center sm:w-[70%]">
                    <div className="flex-shrink-0 hidden sm:flex">
                      <button
                        className={` text-black w-full md:w-36 sm:w-1/2   py-1 block justify-center items-center px-3 ${ activeTab === "myfollowers" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                        onClick={() => setActiveTab("myfollowers")}
                      >
                        <span className="font-bold text-[20px]">
                          {followers ? followers?.length : "0"}
                        </span>
                        <br />
                        <span>Followers </span>
                      </button>
                    </div>

                    <div className="flex-shrink-0  hidden sm:flex">
                      <button
                        className={` text-black w-full md:w-36 sm:w-1/2 py-1 block justify-center items-center px-3 ${ activeTab === "following" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                        onClick={() => setActiveTab("following")}
                      >
                        <span className="font-bold text-[20px]">
                          {following ? following?.length : "0"}
                        </span>
                        <br />
                        <span> Following </span>
                      </button>
                    </div>

                    <div className="flex-shrink-0  hidden sm:flex">
                      <button
                        className={` text-black w-full md:w-36 sm:w-1/2   py-1 block justify-center items-center px-3 
                          ${
                            activeTab === "requests"
                              ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform"
                              : ""
                          }`}
                        onClick={() => setActiveTab("requests")}
                      >
                        <span className="font-bold text-[20px]">
                          {followReq ? followReq?.length : "0"}
                        </span>{" "}
                        <br />
                        <span>Requests </span>
                      </button>
                    </div>
                    <div className="flex-shrink-0  hidden sm:flex">
                    <button
                      className={` text-black w-full md:w-36 sm:w-1/2   py-1 block justify-center items-center px-3 
                        ${
                          activeTab === "suggest"
                            ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform"
                            : ""
                        }`}
                      onClick={() => setActiveTab("suggest")}
                    >
                      <span className="font-bold text-[20px]">
                        {suggestMember ? suggestMember?.length : "0"}
                      </span>{" "}
                      <br />
                      <span>Suggestions </span>
                    </button>
                  </div>
                    <div className="flex flex-col sm:flex-row mt-1">
                      <button
                        className=" sm:w-[90px] ml-2 flex items-center text-green-600 border border-green-600 px-3 py-1 rounded-lg sm:px-4 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                        onClick={handleGoBack}
                      >
                        <IoArrowBackSharp className="mr-2" />
                        <span className="inline-flex items-start justify-center">Back</span>
                      </button>
                      <button
                        className="w-[90px] text-red-600 border border-red-600 px-3 py-1 rounded-lg sm:px-3 sm:py-2 hover:bg-red-600 hover:text-white transition-all duration-500 ease-in-out transform ml-2 sm:mt-0 mt-2 flex items-center"
                        onClick={handleLogout}
                      >
                        <MdLogout/>
                        <span className="ml-2 inline-flex items-start justify-center">Logout</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className={`sm:mt-[30px] mb-1`} />
              <div className="flex sm:flex-row justify-between mt-3">
                <div className="flex justify-between items-center w-full overflow-x-auto md:w-full xl:w-full">
                  <div className="flex-shrink-0 mx-2">
                    <button
                      className={`text-black w-full md:w-36 sm:w-1/2 flex justify-between sm:justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "my profile" ? "bg-[#005AE6] text-white rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("my profile")}
                    >
                      <FaRegUser className="w-[14px] sm:w-5 h-[14px] sm:h-5 md:mr-2 sm:mr-0" />
                      <span className="mx-1 flex items-center justify-center">Profile</span>
                    </button>
                  </div>

                  {/* <div className="flex-shrink-0 mx-3">
                    <button className={` text-black w-full md:w-36 sm:w-1/2   py-3 flex justify-center items-center px-3 
                   ${activeTab === 'myaddress' ? 'bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform' : ''
                      }`}
                      onClick={() => setActiveTab('myaddress')}>
                      <GrLocation className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> My Address
                    </button>
                  </div> */}

                  {/*<div className="flex-shrink-0 mx-3">
                    <button
                      className={` text-black w-full md:w-36 sm:w-1/2   py-3 flex justify-center items-center px-3 
                   ${
                     activeTab === "orders"
                       ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform"
                       : ""
                   }`}
                      onClick={() => setActiveTab("orders")}
                    >
                      <GoChecklist className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> Orders
                    </button>
                  </div>*/}

                  <div className="flex-shrink-0 mx-3">
                    <button
                      className={` text-black w-full md:w-36 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "mypost" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("mypost")}
                    >
                      <MdOutlinePostAdd className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Post</span>
                    </button>
                  </div>

                  <div className="flex-shrink-0 mx-3">
                    <button
                      className={`text-black w-full md:w-48 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "product_post" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("product_post")}
                    >
                      <MdProductionQuantityLimits className="w-5 sm:w-6 h-5 sm:h-6 mr-2" />
                      <span className="mx-1 flex items-center justify-center">Product</span>
                    </button>
                  </div>
                  <div className="flex-shrink-0 mx-3">
                    <button
                      className={`text-black w-full md:w-48 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "feed" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("feed")}
                    >
                      <MdNotificationsActive className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Feed</span>
                    </button>
                  </div>

                  <div className="flex-shrink-0 mx-3">
                    <button
                      className={`text-black w-full md:w-48 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "mycommunity" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("mycommunity")}
                    >
                      <FaLayerGroup className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Community</span>
                    </button>
                  </div>
                  <div className="flex-shrink-0 sm:hidden flex">
                    <button
                      className={` text-black w-full md:w-48 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "myfollowers" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("myfollowers")}
                    >
                      <GrGroup className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Followers</span>
                    </button>
                  </div>

                  <div className="flex-shrink-0  sm:hidden flex">
                    <button
                      className={`text-black w-full md:w-36 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "following" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("following")}
                    >
                      <GrGroup className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Following</span>
                    </button>
                  </div>

                  <div className="flex-shrink-0  sm:hidden flex">
                    <button
                      className={`text-black w-full md:w-36 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "requests" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                      onClick={() => setActiveTab("requests")}
                    >
                      <GrGroup className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                      <span className="mx-1 flex items-center justify-center">Requests</span>
                    </button>
                  </div>
                  <div className="flex-shrink-0  sm:hidden flex">
                  <button
                    className={`text-black w-full md:w-36 sm:w-1/2 flex justify-center items-center px-3 py-2 sm:p-3 ${ activeTab === "suggest" ? "bg-[#005AE6] rounded-lg text-white hover:bg-blue-900 transition-all duration-300 ease-in-out transform" : "" }`}
                    onClick={() => setActiveTab("suggest")}
                  >
                    <GrGroup className="w-5 sm:w-6 h-5 sm:h-6 mr-2" /> 
                    <span className="mx-1 flex items-center justify-center">Suggestions</span>
                  </button>
                </div>
                </div>
              </div>
            </div>

            {activeTab !== "myaddress" &&
              activeTab !== "orders" &&
              activeTab !== "mypost" &&
              activeTab !== "mycommunity" &&
              activeTab !== "product_post" &&
              activeTab !== "feed" &&
              activeTab !== "wishlists" &&
              activeTab !== "myfollowers" &&
              activeTab === "my profile" && (
                <div className="bg-white p-4 rounded-xl mt-4">
                  <div className="flex justify-between mx-4 mb-9">
                    <p className="ml-0 text-center sm:ml-10 xl:text-2xl font-bold sm:mb-0 md:text-2xl xl:mb-4">
                      All Personal Informations
                    </p>
                    <button
                      className="text-[#c16452] font-bold text-lg flex items-center"
                      onClick={() => setUpdateProfile(true)}
                    >
                      <CiEdit className="w-7 h-7 mr-2 sm:mr-4 " /> Edit
                    </button>
                  </div>
                  <div className="flex flex-wrap w-full sm:w-[93%] m-auto">
                    <div className="w-full sm:w-1/2 px-2 mb-14">
                      <div className="flex items-center">
                        <div className="bg-[#F2F7FD] rounded-xl mr-10">
                          <FaRegUser className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                        </div>
                        <div>
                          <p className="text-gray-500 text-base">Name</p>
                          <span className="text-base font-semibold capitalize">
                            {getprofiledata.firstname} {getprofiledata.lastname}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-2 mb-14">
                      <div className="flex items-center">
                        <div className="bg-[#F2F7FD] rounded-xl mr-10">
                          <BsTelephone className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                        </div>
                        <div>
                          <p className="text-gray-500 text-base">
                            Mobile Number
                          </p>
                          <span className="text-base font-semibold">
                            {getprofiledata.phone}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-2 mb-14">
                      <div className="flex items-center">
                        <div className="bg-[#F2F7FD] rounded-xl mr-10">
                          <VscMail className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                        </div>
                        <div className=" flex flex-col flex-wrap">
                          <p className="text-gray-500 text-base">Email</p>
                          <span className="text-base font-semibold inline-flex flex-wrap">
                            <span>{getprofiledata.email}</span>
                            <span className="ml-0 sm:ml-0 lg:ml-7">
                              {getprofiledata.verifyEmail === true && (
                                <span className="inline-flex items-center rounded-xl bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                                  Verified
                                </span>
                              )}
                              {getprofiledata.verifyEmail === false && (
                                <span className="inline-flex items-center rounded-xl bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                                  Not Verified
                                </span>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-2 mb-14">
                      <div className="flex items-center">
                        <div className="bg-[#F2F7FD] rounded-xl mr-10">
                          <MdOutlineDateRange className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                        </div>
                        <div>
                          <p className="text-gray-500 text-base">
                            Date of birth
                          </p>
                          <span className="text-base font-semibold">
                            {getprofiledata.dob}
                          </span>
                        </div>
                      </div>
                    </div>
                    {getprofiledata?.bio && (
                      <div className="w-full sm:w-1/2 px-2 mb-14">
                        <div className="flex items-center">
                          <div className="bg-[#F2F7FD] rounded-xl mr-10">
                            <FaWpforms className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                          </div>
                          <div>
                            <p className="text-gray-500 text-base">Bio</p>
                            <span className="text-base font-semibold capitalize">
                              {getprofiledata?.bio}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {getprofiledata.location && (
                      <div className="w-full sm:w-1/2 px-2 mb-14 flex items-center">
                        <div className="bg-[#F2F7FD] rounded-xl sm:mr-10 w-[50px] h-[50px] mr-10">
                          <GrLocation className="text-[#c16452] w-[50px] h-[50px] p-4 rounded-lg" />
                        </div>
                        {getprofiledata.location}
                      </div>
                    )}
                  </div>
                </div>
              )}
          </>
          <div className="pt-5">
            {activeTab === "orders" && <Orders />}

            {activeTab === "wishlists" && <Wishlist />}

            {activeTab === "mypost" && <ProfilePost activeTab={"mypost"} selectedTab="mypost" />}

            {activeTab === "product_post" && (
              <ProfilePost activeTab={"product_post"} selectedTab="product_post" />
            )}

            {activeTab === "feed" && <UserProfilefeed activeTab={"feed"} />}
            {activeTab === "mycommunity" && <ProfileCommunity />}

            {activeTab === "myfollowers" && (
              <MyFollowers
                activeTab={activeTab}
                handleGetFollowers={handleGetFollowers}
                followers={followers}
              />
            )}

            {activeTab === "following" && <MyFollowers activeTab={activeTab} />}
            {activeTab === "requests" && <MyFollowers activeTab={activeTab} />}
            {activeTab === "suggest" && <MyFollowers activeTab={activeTab} />}
            {/* {isBgImg && (
              <ImageUpload
                setIsBgImg={setIsBgImg}
                setIsProfileImg={setIsProfileImg}
                heading="Select the background image (only PNG and JPG)"
                text="Please upload the background image for your profile. Ensure that the size is within 5 MB"
                uploadType={isBgImg ? "profileBackgroundImage" : "profileImage"}
                handleGetProfileData={handleGetProfileData}
              />
            )}
            {isProfileImg && (
              <ImageUpload
                setIsBgImg={setIsBgImg}
                setIsProfileImg={setIsProfileImg}
                heading="Select the Profile image (only PNG and JPG)"
                text="Please upload your profile image. Ensure that the size is within 5 MB"
                uploadType={
                  isProfileImg ? "profileImage" : "profileBackgroundImage"
                }
                handleGetProfileData={handleGetProfileData}
              />
            )} */}

            {updateProfile ? (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
                <div className="w-full sm:w-9/12  p-4 absolute top-0">
                  <UpdateProfile
                    getprofiledata={getprofiledata}
                    updateProfile={updateProfile}
                    setUpdateProfile={setUpdateProfile}
                  />
                </div>
              </div>
            ) : null}
            {isImageCrop && croppedProfileImg && (
              <ImageCropper
                closeCrop={handleCloseCrop}
                imageUrl={croppedProfileImg}
                croppedImgType={cropImageType}
                setIsImageCrop={handleCloseCrop}
                handleGetProfileData={handleGetProfileData}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
