import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiurl,marketplaceapiurl } from "../config/config";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getCookie } from "../config/coockies";
import Layout from "../components/layout";
import { IoLocationOutline, IoArrowBackSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { FaComment, FaShare, FaCheck, FaThumbsUp } from "react-icons/fa";
import { GetTimeAgo } from "../components/getTime";
import { useUser } from "../contexts/UserProvider"
import { FaBell, FaBellSlash } from 'react-icons/fa'; 
const UserProfilefeed = () => {
  const token = getCookie("savage_token");
  const { userid } = useParams();
  const { userData } = useUser();
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const[buynowquery,setBuynowquery]=useState(false)
  // const navigate = useNavigate();
  // const handleGoBack = () => {
  //   navigate(-1);
  // };

  const handleUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiurl}/notifications/get-all-notifications/${userData._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user profile data >>>>>", response);
      if (response.data.success === true) {
        setLoading(false);
        setProfileData(response.data.notifications);
        handleUserproductnotification();
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };
  const handleUserproductnotification = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${marketplaceapiurl}/notification/getNotification`,{seller_email:userData.email}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user profile data >>>>>", response);
      if (response.data.status === 200) {
        setLoading(false);
        const newNotifications=response.data.data;
        // console.log(response.data.data)
        setProfileData((prevData) => [...prevData, ...newNotifications]);
      } else {
        setLoading(false);
        //toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };
  useEffect(() => {
    
    handleUserProfile();
    
  }, [userid]);

// console.log(profileData)
  const [isSilenced, setIsSilenced] = useState(true);

  // Function to toggle silence state
  const toggleSilence = async() => {
  
    
    try {
      const mydata = {
        userId: userData._id
      }
      // console.log('user submitting data', mydata)
      const response = await axios.post(`${apiurl}/notifications/toggle-notification`, mydata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user response", response);
      if (response.status === 200) {
        setIsSilenced(response.data.status);
        
          toast.info(response.data.message)
       
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("user register", error);
    }
   
  };
  const getnotifictionstatus = async() => {
  
    
    try {

      // console.log('user submitting data', mydata)
      const response = await axios.get(`${apiurl}/notifications/get-notification-status/${userData._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log("user response", response);
      if (response.status === 200) {
        setIsSilenced(response.data.status);
       
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("user register", error);
    }
   
  };
  useEffect(()=>{
    getnotifictionstatus()
  },[])
  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      
        <div className="dark:bg-gray-700 bg-gray-200 p-4">
          {/* <button
            className="flex items-center text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
            onClick={handleGoBack}
          >
            <IoArrowBackSharp className="mr-2" /> Back
          </button> */}
          <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 mt-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border sm:mt-5">
          <div className="ml-4 flex justify-end">
          <label className="relative inline-flex items-end cursor-pointer">
            <input 
              type="checkbox" 
              className="sr-only peer" 
              checked={isSilenced}
              onChange={toggleSilence} 
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600"></div>
            <div className="dot absolute top-0.5 left-0.5 bg-white w-5 h-5 rounded-full transition peer-checked:translate-x-full peer-checked:bg-blue-500"></div>
          </label>
          {/* Optional label to indicate silenced or not */}
          <span className="ml-2 text-sm text-gray-600">
            {isSilenced ? 'Silenced' : 'Active'}
          </span>
        </div>
        {isSilenced?
          profileData && (
              <div className="border-b pb-6 w-[95%] sm:w-11/12 m-auto">
              {/* Notification Header */}
              <div className="flex justify-between items-center mt-4">
                <h2 className="text-lg font-semibold">Notifications</h2>
                <h2 className="cursor-pointer text-blue-600" onClick={()=>setBuynowquery(!buynowquery)}>{buynowquery?"See Group Notification":"See Product Notification"}</h2>
              </div>
    
              {/* Notification List */}
              <div className="flex flex-col justify-center mt-4 space-y-4">
              {!buynowquery?
              profileData.map((notification, index) => (
               notification.type?
                <div
                  key={index}
                  className={`flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer ${notification.isRead ? '' : 'bg-blue-50'}`}
                >
                
                  {/* User Avatar */}
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={notification?.user?.hero_img || notification?.friendImage || notification?.senderImage || notification?.inviter?.hero_img ||'/profile.png'} // Fallback to a default image if hero_img is missing
                    alt="User Avatar"
                  />
              
                  {/* Notification Content */}
                  <div className="flex-1">
                    <p className="text-sm text-gray-700">
                      {/* Link to user profile */}
                      <Link to={`/profile/${notification?.user?.userId || notification?.friendId ||  notification?.senderId|| notification?.inviter?.userId ||''}`} 
                        className="font-semibold text-blue-600 hover:underline capitalize"
                      >
                        {notification?.user?.username || notification?.friendName || notification?.senderName || notification?.inviter?.username ||'Unknown User'}
                      </Link>
                      
                      {/* Notification Types */}
                      {notification.type === 'like' && (
                        <> liked your post titled 
                          <Link to={`/community/${notification.community.communityHandle}/posts/${notification.postId}`} 
                            className="ml-1 font-semibold text-blue-600 hover:underline"
                          >
                            {notification.postTitle}
                          </Link>
                        </>
                      )}
                      {notification.type === 'friend_request' && (
                        <> sent you a friend request</>
                      )}
                      {notification.type === 'community_like' && (
                        <> liked a post in the community 
                          <Link to={`/community/${notification.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1 mr-1"
                          >
                            {notification.communityName}
                          </Link> 
                          titled 
                          <Link to={`/community/${notification.communityHandle}/posts/${notification.postId}`} 
                            className="ml-1 font-semibold text-blue-600 hover:underline"
                          >
                            {notification.postTitle}
                          </Link>
                        </>
                      )}
                      {notification.type === 'community_post' && (
                        <> posted in the community 
                          <Link to={`/community/${notification.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1 mr-1"
                          >
                            {notification.communityName}
                          </Link> 
                          titled 
                          <Link to={`/community/${notification.communityHandle}/posts/${notification.postId}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.postTitle}
                            {notification.postImage[0].url!=null?
                            notification.postImage.map((item)=>{
                                  return <img src={item.url} className="w-20 h-20"/>
})
                        :""}
                          </Link>
                        </>
                      )}
                      {notification.type === 'invite' && (
                        <> invited you to join the community 
                          <Link to={`/community/${notification.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.communityName}
                          </Link>
                        </>
                      )}
                        {notification.type === 'community_join' && (
                        <> Have joined the community 
                          <Link to={`/community/${notification.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.communityName}
                          </Link>
                        </>
                      )}

                      
                      {notification.type === 'follow_request_accepted' && (
                        <> accepted your follow request</>
                      )}
                      {notification.type === 'community_comment' && (
                        <> commented on your post titled 
                          <Link to={`/community/${notification.communityHandle}/posts/${notification.postId}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1 mr-1"
                          >
                            {notification.postTitle}
                          </Link> 
                          in the community 
                          <Link to={`/community/${notification.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.communityName}
                          </Link>: "{notification.commentText}"
                        </>
                      )}
                        {notification.type === 'comment' && (
                        <> commented on post  
                          <Link to={`/community/${notification.community.communityHandle}/posts/${notification.postId}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1 mr-1"
                          >
                            {notification.postTitle}
                          </Link> 
                          in the community 
                          <Link to={`/community/${notification.community.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.communityName}
                          </Link>: "{notification.commentText}"
                        </>
                      )}
                          {notification.type === 'reply' && (
                        <> replied on post  
                          <Link to={`/community/${notification.community.communityHandle}/posts/${notification.postId}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1 mr-1"
                          >
                            {notification.postTitle}
                          </Link> 
                          in the community 
                          <Link to={`/community/${notification.community.communityHandle}`} 
                            className="font-semibold text-blue-600 hover:underline ml-1"
                          >
                            {notification.community.communityName}
                          </Link>: "{notification.replyText}"
                        </>
                      )}
                    </p>
                    <p className="text-xs text-gray-500">{new Date(notification.createdAt).toLocaleString()}</p> {/* Format the date */}
                  </div>
                </div>:""

              )):""}
                {buynowquery?
                 profileData.map((notification, index) => (
                  notification.type?"":
                  <div
                  key={index}
                  className={` p-3 rounded-lg hover:bg-gray-100  ${notification.isRead ? '' : 'bg-blue-50'}`}
                >
                <p><span className="capitalize">{notification.name}</span> wants to buy {notification.content}. . Please connect with him/her using email: {notification.customer_email} or phone number: {notification.customer_phone}</p>
                 <p className="text-xs text-gray-500">{new Date(notification.createdAt).toLocaleString()}</p> {/* Format the date */}
                </div>
                 )):""}

              </div>
            </div>
            ):""}
          </div>
         
        </div>
      
    </div>
  );
};

export default UserProfilefeed;
