import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

const Footer = () => {

  const { userData } = useUser()


  return (
    <>
      
        <footer className="bg-gray-800 text-white p-4 fixed bottom-0 w-full">
          <div className="container mx-auto">
            <p>&copy; 2024 Group Hub. All rights reserved.</p>
            {userData?.firstname ? (
            <div className="circle flex justify-center items-center">
              <Link to="/chats"><img src="/assets/chat.gif" alt="chat" className="h-[50px] w-[50px]  rounded-full bg-[#f1fbba] relative z-[9999]" /></Link>
            </div>
          ) : null}
          </div>
        </footer>
      
    </>
  );
};

export default Footer;