import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import { getCookie } from "../config/coockies";
import { toast } from "react-toastify";
import { MdAddPhotoAlternate } from "react-icons/md";
import LoaderSpiner from "./loader";
import { v4 as uuidv4 } from "uuid";
import { awsKeyId, awsSecretKey } from "../config/config";
const aws = require("aws-sdk");

const CreatePost = ({ fetchposts, onCreatePost, searchPost, SetSearchPost, postCount }) => {
  let User = useUser();
  const token = getCookie("savage_token");
  const isSeller = getCookie("is_seller")
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [buyNowUrl, setBuyNowUrl] = useState("");
  const [Community, setCommunity] = useState([]);
  const { communityHandle } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [Protectedpassword, setProtectedPassword] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [imagePop, setImagePop] = useState(false);
  const [selectedPost, setSelectedPost] = useState("simplePost");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const handleChangePost = (post) => {
    setSelectedPost(post);
  };

  const fetchCommunities = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/handle/${communityHandle}`,
      data: { password: Protectedpassword },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log("Community", response.data);
        setCommunity(response.data.community);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCommunities();
  }, [communityHandle]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };
  const handleBuyNowChange = (e) => {
    setBuyNowUrl(e.target.value);

  };

  const handleCreatePost = async () => {
    // console.log("token post", token)
    if (
      (selectedPost === "productPost" && !title)
    ) {
      toast.error(`Please add product title`);
      return;
    }
    if (
      (selectedPost === "productPost" && !content)
    ) {
      toast.error(`Please add product description`);
      return;
    }
    if (
      (selectedPost === "productPost" && !buyNowUrl)
    ) {
      toast.error(`Please add product url`);
      return;
    }
    if (
      (selectedPost === "productPost" && !imageUrl)
    ) {
      toast.error(`Please add product image`);
      return;
    }

    if (selectedPost === "productPost" && !buyNowUrl.includes("https://savageseller.com/")) {
      toast.error("Please add a valid Savage Seller product URL.");
      return;
    }
    
    setLoading(true);
    if (title !== "" && content !== "") {
      try {
        let data = {
          title: title,
          content: content,
          buyNowUrl: buyNowUrl,
          communityId: Community?._id,
          userId: User?.userData?._id,
          images: imageUrl,
          images: [
            {
              url: imageUrl,
              description: title,
            },
          ],
        };
        const response = await axios.post(`${apiurl}/post/create`, data, {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.data.success === true) {
          setTitle("");
          setImageUrl("");
          setContent("");
          setBuyNowUrl("");
          setLoading(false);
          fetchposts();
          toast.success(response.data.message);
          onCreatePost(response.data.post);
        } else {
          setLoading(false);
          // console.log(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("create community post error", error);
      }
    } else {
      setLoading(false);
      toast.error("Please fill the all details");
    }
  };

  // -----------------------------

  const handleUpload = async (imageType, post_img) => {
    setImagePop(false);
    setImageLoading(true);
    setImageUrl(post_img);
    try {
      const updatedData = {
        [imageType]: post_img,
      };
      const response = await axios.put(
        `${apiurl}/user/update/${User?.userData?._id}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setImageLoading(false);
        setImagePop(true);
       // toast.success("Post Image Uploaded Successfully");
      } else {
        toast.error("Failed to Image Uploaded");
      }
    } catch (error) {
      console.error("Image Uploaded error", error);
    }
  };

  const ImageHandler = async (e) => {
    setImageLoading(true);
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      setImageLoading(false);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    } finally {
    }
  };

  const setImage = async (e) => {
    const image = await ImageHandler(e);
    if (image) {
      await handleUpload("post_img", image);
    }
  };

  const isVideoExtension = (url) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionchange = videoExtensions.toString();
    const extension = getExtension(url);
    const myurl = videoExtensionchange.includes(extension);
    return myurl;
  };

  const getExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className="mb-5 sm:mw-full">
        <ul className="overflow-x-auto">
          <li className="flex items-center rounded-lg mb-4 mt-0 sm:mt-4">
            <div className="bg-white border border-gray-300 rounded-xl py-2 mr-2">
              <Link
                to={`/community/${communityHandle}/posts`}
                onClick={() => handleLinkClick("home")}
                className={`px-8 py-2 font-[500]  border-[#eeeeee] rounded-full hover:rounded-full  hover:border-slate-300  ${activeLink === "home" ? "active-link" : ""
                  }`}
              >
                Post
              </Link>
            </div>
            {/* <div className="bg-white border border-gray-300 rounded-xl py-2 mr-2">
              <Link
                to={`/community/${communityHandle}/about`}
                onClick={() => handleLinkClick("about")}
                className={`px-8 py-2 font-[500]  border-[#eeeeee]  hover:rounded-full  hover:border-slate-300 ${activeLink === "about" ? "active-link" : ""
                  }`}
              >
                About
              </Link>
            </div> */}

            {/* <div className="bg-white border border-gray-300 rounded-full py-2 mr-2">
              <Link
                to={`/community/${communityHandle}/discussion`}
                onClick={() => handleLinkClick("discusion")}
                className={`px-8 py-2 font-[500]  border-[#eeeeee]  hover:rounded-full  hover:border-slate-300 ${activeLink === "discusion" ? "active-link" : ""
                  }`}
              >
                Discussion
              </Link>
            </div> */}
           {/* <div className="bg-white border border-gray-300 rounded-xl py-2 mr-2">
              <Link
                to={`/community/${communityHandle}/products`}
                onClick={() => handleLinkClick("discusion")}
                className={`px-8 py-2 font-[500]  border-[#eeeeee]  hover:rounded-full  hover:border-slate-300 ${activeLink === "discusion" ? "active-link" : ""
                  }`}
              >
                Products
              </Link>
            </div>*/}
            <div className="bg-white border border-gray-300 rounded-xl py-2 mr-2">
              <Link
                to={`/chats`}
                onClick={() => handleLinkClick("chat")}
                className={`px-8 py-2 font-[500]  border-[#eeeeee]  hover:rounded-full  hover:border-slate-300  ${activeLink === "chat" ? "active-link" : ""
                  }`}
              >
                Chats
              </Link>
            </div>
            <div >
              <input type="text"
                className="font-[500]  border-[#eeeeee] bg-white border rounded-xl py-2 mr-2 w-40 px-4"
                placeholder="Search"
                value={searchPost}
                onChange={(e) => SetSearchPost(e.target.value)}
              />
            </div>
          </li>
        </ul>
        <div className="bg-white rounded-xl py-2 pb-5 sm:px-6 px-4 mb-5 mx-0 sm:block border">
          <div className="flex items-center justify-start space-x-5 mb-2 ">
            <button
              onClick={() => handleChangePost("simplePost")}
              className={`font-semibold sm:text-base text-sm my-2 rounded-xl sm:px-8 px-3 py-2 ${selectedPost === "simplePost"
                ? "bg-blue-500 text-white"
                : "border text-black"
                }`}
            >
              Create a Post
            </button>
            <button
              onClick={() => handleChangePost("productPost")}
              className={`font-semibold sm:text-base text-sm my-2 rounded-xl sm:px-8 px-3  py-2 ${selectedPost === "productPost"
                ? "bg-blue-500 text-white"
                : " border text-black"
                }`}
            >
              Create a Product Post
            </button>
          </div>
          <div className="mb-4 ">
            <div className="flex items-center">
              <img
                className="w-[50px] h-[50px] rounded-full border border-gray-500"
                src={User?.userData?.hero_img}
                alt="userphoto"
              />
              <input
                type="text"
                id="postTitle"
                name="postTitle"
                value={title}
                onChange={handleTitleChange}
                className="ms-4 w-full bg-[#F9F9F9] rounded-full outline-none p-3 px-4"
                placeholder={`${selectedPost === "simplePost"
                  ? "Enter your post title"
                  : "Enter product title"
                  }`}
              />
            </div>
          </div>
          <div className="mb-4">
            <textarea
              id="postContent"
              name="postContent"
              value={content}
              onChange={handleContentChange}
              className="m-0 w-full sm:p-5 bg-[#F9F9F9] rounded-lg outline-none p-2 px-4"
              placeholder={`${selectedPost === "simplePost"
                ? `What's on your mind?`
                : "Enter product description"
                }`}
            ></textarea>
          </div>
          {selectedPost === "productPost" && (
            <div className="mb-4">
              <input
                id="buynowUrl"
                name="buynowUrl"
                value={buyNowUrl}
                onChange={handleBuyNowChange}
                className="m-0 w-full sm:p-5 bg-[#F9F9F9] rounded-lg outline-none p-2 px-4"
                placeholder="Enter Product Url Link"
              ></input>
            </div>
          )}
          <div className="flex justify-between">
            <div>
              {imageUrl && imageUrl.length > 0 ? (
                <div onClick={() => setImagePop(false)}>
                  <div className="relative">
                    {isVideoExtension(imageUrl) ? (
                      <video
                        className="w-11/12 h-[150px] rounded-lg relative"
                        controls
                        autoPlay
                        loop
                        muted
                      >
                        {/* {console.log('vode Url in tag', imageUrl)} */}
                        <source src={imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={imageUrl}
                        alt="postimage"
                        className="w-11/12 h-[150px] rounded-lg relative"
                      />
                    )}
                    <span
                      onClick={() => setImageUrl("")}
                      className="absolute top-3 right-9 text-xl z-20 text-black border border-white rounded-full px-3 py-1 cursor-pointer bg-white"
                    >
                      X
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="flex justify-between items-center">
              {isSeller && selectedPost === "productPost" && (
                  <Link
                  to='https://multivendor.savageseller.com/dashboard' target='_blank'
                      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2`}
                      disabled={true}
                    >
                      Copy Product Link
                  </Link>
                )}
              <label
                htmlFor="fileInput"
                className={`${imageUrl && imageUrl.length > 0
                  ? "bg-green-500"
                  : "bg-blue-500"
                  } text-white cursor-pointer rounded mb-0 flex items-center px-2 py-[2px]`}
              >
                <MdAddPhotoAlternate className="text-4xl" />
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  name="post_img"
                  onChange={(e) => {
                    setImage(e);
                  }}
                />
              </label>
              <button
                className={`${imageLoading === true ? "bg-green-500" : "bg-blue-500"
                  } hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2`}
                type="button"
                onClick={handleCreatePost}
                disabled={imageLoading}
              >
                {imageLoading === true ? "Uploading . . ." : "Post"}
              </button>
            </div>
            
          </div>
          {isSeller && selectedPost === "productPost" && (
            <p className="text-xs text-blue-700 text-center mt-2"><span className="text-[#000]">Instruction</span>: You can copy the link after logging into your seller account. Go to the product list tab and copy the link from the product URL column.</p>
          )}
        </div>
        
        <div>
          <p className="bg-[#005AE61A] text-[#005AE6] border mb-2 sm:text-2xl text-xl py-2 rounded-lg text-center border-[#005AE6]">
            Features Posts{" "}
          </p>
          {postCount > 0 && (
            <>
              {postCount > 2 && (
                <Link
                  to={`/community/${communityHandle}/posts`}
                  className="flex justify-end items-center w-full font-semibold text-sm  text-[#0017e6]"
                >
                  View All
                </Link>
              )}
            </>
          )}          
        </div>
      </div>
    </>
  );
};

export default CreatePost;
