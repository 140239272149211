import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";
import "../../src/vendorregister.css";
import { useUser } from "../contexts/UserProvider";
import Header from "../components/header";
import { marketplaceapiurl } from "../config/config";
import { awsKeyId, awsSecretKey } from "../config/config";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import InfoIcon from "@mui/icons-material/Info";
const aws = require("aws-sdk");

const VendorRegister = () => {
  const { userData } = useUser();
 // console.log(userData);
  // const token = getCookie("savage_token");
  const [isgstValid, setisgstValid] = useState(true);
  const [ispanValid, setispanValid] = useState(true);
  const [isPanPresent, setispanPresent] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [loading, setLoading] = useState({
    image1: false,
    image2: false,
    button: false,
  });
  const rawData = {
    name: userData.name,
    email: userData.email,
    company_name: userData.name,
    // contact_no: "+1"+userData.phone,
    contact_no: userData.phone,
    personal_address: "",
    profile_image: "",
    seller_id: 0,
    role_id: 2,
    status: 1,
    area_code: "",
    city: "",
    state: "",
    street: "",
    // bank_account:"",
    // beneficiary_name:"",
    // ifsc_code:"",
    fulfillment_option: "",
    shipping_method: "",
    description: "",
    terms: "",
    commission: 1,
    sellerType: "",
    pickup:"",
    dropoff:"",
    meetpublicplace:"",
    pickdropmeet:1,
    password:userData.password,
    paid: true
  };
  const rawErrorData = {
    name: false,
    email: false,
    company_name: false,
    contact_no: false,
    personal_address: false,
    profile_image: false,
    area_code: false,
    city: false,
    state: false,
    street: false,
    // bank_account:false,
    //beneficiary_name:false,
    //ifsc_code:false,
    fulfillment_option: false,
    shipping_method: false,
    description: false,
    terms: false,
    commission: false,
    sellerType: false,
    pickup:false,
    dropoff:false,
    meetpublicplace:false,
    pickdropmeet:false
  };
  const [sellerData, setSellerData] = useState(rawData);
  const [sellerDataError, setSellerDataError] = useState(rawErrorData);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const inputHandler = (e) => {
    if (e.target.name === "contact_no") {
      if (e.target.value === "+1" || e.target.value === "") {
        setSellerData({ ...sellerData, [e.target.name]: "+1" });
      } else if (e.target.value.length === 1 && !isNaN(e.target.value)) {
        setSellerData({
          ...sellerData,
          [e.target.name]: "+1" + e.target.value,
        });
      } else if (e.target.value.length > 1 && !isNaN(e.target.value)) {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
      }
    } else {
      setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
  };
  const errorHandler = (e) => {
    // console.log(e.target.name);

    if (e.target.value) {
      setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
    } else {
      setSellerDataError({ ...sellerDataError, [e.target.name]: true });
    }
  };
  // console.log("isGSTValid--->", isgstValid)
  const newUserCreatehandler = async () => {
    setLoading({ ...loading, button: true });
    setIsSubmit(true);
    // console.log("sellerData => ", sellerData);
    // console.log("sellerDataError => ", sellerDataError);
    const {
      name,
      email,
      company_name,
      contact_no,
      personal_address,
      profile_image,
      seller_id,
      role_id,
      area_code,
      city,
      state,
      street,
      // bank_account,
      // beneficiary_name,
      //  ifsc_code,
      fulfillment_option,
      shipping_method,
      description,
      terms,
      commission,
    } = sellerData;
    if (
      name &&
      email &&
      company_name &&
      contact_no &&
      area_code &&
      city &&
      state &&
      personal_address &&
      //&& bank_account && beneficiary_name && ifsc_code
      fulfillment_option &&
      description &&
      profile_image &&
      terms &&
      seller_id === 0 &&
      role_id
    ) {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/vendorregister`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { ...sellerData, sellerType: "individual",approval_status:1,pickdropmeet:1 },
      });
      //const { status } = data;
      // console.log("asyy", data.status);

      if (data.status === 200) {
        toast.success("New seller registered !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading({ ...loading, button: false });
        setIsSubmit(false);
        window.open("https://multivendor.savageseller.com/", "_blank");
        setTimeout(() => {
          window.location.href = "/profile"; 
        }, 5000);
        return true;
      }
      if (data.status == 301) {
        setIsSubmit(false);
        toast.warning("Already registered !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setIsSubmit(false);
        window.open("https://multivendor.savageseller.com/", "_blank");
        window.location.href = "/profile";
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    } else {
      setIsSubmit(false);
      toast.error("Please fill all the fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateUserHandler = async () => {};
  const ImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      // console.log(Location);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  };

  const setImage = async (e) => {
    setIsImageUploading(true); // Set uploading status to true

    const image = await ImageHandler(e);
    // console.log("Image----", image);
    setSellerData({ ...sellerData, [e.target.name]: image });
    setIsImageUploading(false);
  };

  useEffect(() => {}, []);

  const handleTooltip = (type) => {
    setActiveTooltip(type);
  };

  const hideTooltip = () => {
    setActiveTooltip(null);
  };

  return (
    <>
      <Header />

      <div className="m-0 w-100 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6
                className="m-0 font-weight-bold text-primary"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                Register yourself as a Individual Vendor
              </h6>
            </div>
            <div className="card-body">
              <div className="grid md:grid-cols-3 gap-1">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Full Legal Name
                      <span className="text-danger"> * </span>
                      {sellerDataError?.name !== "valid" &&
                      sellerDataError?.name !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={sellerData.name}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                {/*<div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Company name<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                        <span className="text-danger text-[12px]" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                disabled={isImageUploading}
                                            />
                                        </div>
                                            </div>*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Email address<span className="text-danger">*</span>
                      {sellerDataError?.email !== "valid" &&
                      sellerDataError?.email !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={sellerData.email}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      readOnly
                      disabled={isImageUploading}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel flex">
                      Contact No.<span className="text-danger">*</span>
                      {sellerDataError?.contact_no !== "valid" &&
                      sellerDataError?.contact_no !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <p className="text-blue-500 text-xs top-2 left-2">
                        Only USA phone numbers are allowed
                      </p>
                    </label>

                    <input
                      type="text"
                      name="contact_no"
                      className="form-control"
                      placeholder="Contact number"
                      value={sellerData.contact_no}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                      maxLength={12}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel flex">
                      {" "}
                      Profile Picture image
                      <span className="text-danger">*</span>
                      {sellerDataError?.profile_image !== "valid" &&
                      sellerDataError?.profile_image !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      {isImageUploading ? (
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="20"
                            className="loading"
                            style={{ color: "#0ba934e0" }}
                          >
                            {" "}
                            <path
                              fill="#008DD2"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                          <span
                            style={{ color: "#0ba934e0", fontSize: "12px" }}
                          >
                            Uploading.....
                          </span>
                        </div>
                      ) : null}
                    </label>

                    <input
                      type="file"
                      name="profile_image"
                      className="form-control"
                      onChange={(e) => setImage(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Country</label>

                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value="USA"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Address</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.personal_address !== "valid" &&
                    sellerDataError?.personal_address !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="personal_address"
                      placeholder="Address"
                      value={sellerData.personal_address}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">State</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.state !== "valid" &&
                    sellerDataError?.state !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      placeholder="State"
                      value={sellerData.state}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">City</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.city !== "valid" &&
                    sellerDataError?.city !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="City"
                      value={sellerData.city}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Postal/ZIP code </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.area_code !== "valid" &&
                    sellerDataError?.area_code !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="area_code"
                      placeholder="Zipcode"
                      value={sellerData.area_code}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Street</label>
                    {/*<span className="text-danger"> * </span>

                                        {sellerDataError?.street !== "valid" &&
                                        sellerDataError?.street !== false ? (
                                            <span className="text-danger text-[12px]">
                                            {" "}
                                            This field is required !{" "}
                                            </span>
                                        ) : null}*/}
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      placeholder="Street"
                      value={sellerData.street}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                {/*<div className="col-md-6">
                      <div className="form-group">
                      <label className="genericlabel">
                          Bank Account Number
                          <span className="text-danger"> * </span>
                          
                      </label>
  
                      {sellerDataError?.bank_account !== "valid" &&
                      sellerDataError?.bank_account !== false ? (
                          <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                          </span>
                      ) : null}
                      <input
                          type="text"
                          className="form-control"
                          name="bank_account"
                          placeholder="Bank account"
                          value={sellerData.bank_account}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={isImageUploading}
                      />
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                      <label className="genericlabel">Account holder's name </label>
                      <span className="text-danger"> * </span>
  
                      {sellerDataError?.beneficiary_name !== "valid" &&
                      sellerDataError?.beneficiary_name !== false ? (
                          <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                          </span>
                      ) : null}
                      <input
                          type="text"
                          className="form-control"
                          name="beneficiary_name"
                          placeholder="Account holder's name"
                          value={sellerData.beneficiary_name}
                          onChange={(e) => inputHandler(e)}
                          onBlur={(e) => errorHandler(e)}
                          disabled={isImageUploading}
                      />
                      </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Routing Number </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.ifsc_code !== "valid" &&
                    sellerDataError?.ifsc_code !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="ifsc_code"
                      placeholder="Routing Number"
                      value={sellerData.ifsc_code}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                  </div>*/}

                <div className="col-md-6">
                  <div className="form-group">
                  <label className="genericlabel">Pickup Address</label>
                    <span className="inline-block cursor-pointer ml-1"
                      onMouseEnter={() => handleTooltip('pickup')}
                      onMouseLeave={hideTooltip}
                      >
                      <InfoIcon color="primary" fontSize="small" />
                      {activeTooltip === 'pickup' && (
                        <div
                          className="custom-tooltip"
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            left: "60%",
                            transform: "translateX(-50%)",
                            backgroundColor: "#333",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            zIndex: 1,
                            whiteSpace: "nowrap",
                          }}
                        >
                          Adding a pickup location helps customers know where to collect their orders, improving their overall experience.
                        </div>
                      )}
                    </span>
                    <textarea
                      type="text"
                      className="form-control"
                      name="pickup"
                      placeholder="Pickup Address"
                      value={sellerData.pickup}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Dropoff Address</label>
                    <span className="inline-block cursor-pointer ml-1"
                      onMouseEnter={() => handleTooltip('dropoff')}
                      onMouseLeave={hideTooltip}
                      >
                      <InfoIcon color="primary" fontSize="small" />
                      {activeTooltip === 'dropoff' && (
                        <div
                          className="custom-tooltip"
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            left: "30%",
                            transform: "translateX(-50%)",
                            backgroundColor: "#333",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            zIndex: 1,
                            whiteSpace: "nowrap",
                          }}
                        >
                          Make sure to include a drop-off location to streamline the return or exchange process for your customers.
                        </div>
                      )}
                    </span>
                    <textarea
                      type="text"
                      className="form-control"
                      name="dropoff"
                      placeholder="Dropoff Address"
                      value={sellerData.dropoff}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Meet at public place</label>
                    <span className="inline-block cursor-pointer ml-1"
                      onMouseEnter={() => handleTooltip('meetup')}
                      onMouseLeave={hideTooltip}
                      >
                      <InfoIcon color="primary" fontSize="small" />
                      {activeTooltip === 'meetup' && (
                        <div
                          className="custom-tooltip"
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            left: "60%",
                            transform: "translateX(-50%)",
                            backgroundColor: "#333",
                            color: "#fff",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            zIndex: 1,
                            whiteSpace: "nowrap",
                          }}
                        >
                          Make sure to specify a meet-at public place to offer customers a safe location for pickups and deliveries.
                        </div>
                      )}
                    </span>
                    <textarea
                      type="text"
                      className="form-control"
                      name="meetpublicplace"
                      placeholder="Meet at public place address"
                      value={sellerData.meetpublicplace}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="genericlabel">
                    {" "}
                    Fulfillment Option <span className="text-danger">*</span>
                    {sellerDataError?.fulfillment_option !== "valid" &&
                    sellerDataError?.fulfillment_option !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <select
                      name="fulfillment_option"
                      id=""
                      className="form-control"
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    >
                      <option value="" readonly>Select Fulfillment options</option>
                      <option value="UPS">UPS</option>
                      <option value="USPS">USPS</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Description </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.description !== "valid" &&
                    sellerDataError?.description !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="Description what you are offering"
                      value={sellerData.description}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      {" "}
                      Marketplace Agreement{" "}
                    </label>

                    <span className="text-danger"> * </span>

                    {sellerDataError?.terms !== "valid" &&
                    sellerDataError?.terms !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsAgreement"
                        checked={sellerData.terms}
                        name="terms"
                        onChange={(e) => {
                          const { checked } = e.target;
                          setSellerData({ ...sellerData, terms: checked });
                        }}
                        disabled={isImageUploading}
                      />
                      <label
                        className="form-check-label px-2"
                        htmlFor="termsAgreement"
                      >
                       <Link to="/marketplaceTerms" target="_blank"> I agree to Savage seller terms and policies</Link>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary savebtn w-full flex items-center justify-center"
                    onClick={newUserCreatehandler}
                    disabled={isSubmit}
                  >
                    {isSubmit ? <>
                    <svg
                      width="24px"
                      height="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      stroke="#ffffff"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                    </svg>{" "}
                    <span className="mt-[2px]">Loading</span>
                    </> : `Save` }
                  </button>
                </div>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VendorRegister;
